import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useCompanyContactColumns } from '../../constants';
import { useSnackBars } from '../../hooks';
import { ArrayOfId, MasterEntityType } from '../../propTypes';
import { FormDataGrid } from '../dataGrids';

const CompanyContactsFormGrid = ({
  masterEntity,
  queryDocument,
  createQueryDocument,
  setSelectedContact,
  selectedContacts,
  checkbox,
}) => {
  const [contacts, setContacts] = useState([]);
  const { addAlert } = useSnackBars();
  const masterEntityId = masterEntity.id;
  const masterEntityRut = masterEntity.rut;
  const {
    loading,
    refetch,
    data: companyContactData,
  } = useQuery(queryDocument, {
    variables: { masterEntityId },
    skip: !masterEntityId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setContacts(Object.values(data)[0]);
    },
  });
  const originalContacts = companyContactData ? Object.values(companyContactData)[0] : [];
  const onSubmit = () => {
    refetch();
  };

  const [createContact] = useMutation(createQueryDocument, {
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Contactos creado',
      });
      onSubmit();
    },
  });

  const onCreateNewContact = useCallback(
    (row) => {
      createContact({
        variables: {
          isNew: true,
          company: masterEntityRut,
          name: row.name,
          email: row.email,
          phoneNumber: row.phoneNumber,
          position: row.position,
        },
      });
    },
    [masterEntityRut, createContact],
  );
  return (
    <FormDataGrid
      rows={contacts}
      setRows={setContacts}
      columns={useCompanyContactColumns()}
      loadingWithSkeleton={loading}
      boxProps={{
        sx: { height: 300 },
      }}
      hideFooter
      originalRows={originalContacts}
      onCreate={onCreateNewContact}
      checkboxSelection={checkbox}
      onSelectionModelChange={setSelectedContact}
      selectionModel={selectedContacts}
      isBackoffice={false}
    />
  );
};

CompanyContactsFormGrid.propTypes = {
  masterEntity: MasterEntityType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryDocument: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  createQueryDocument: PropTypes.object.isRequired,
  setSelectedContact: PropTypes.func,
  selectedContacts: ArrayOfId,
  checkbox: PropTypes.bool,
};

CompanyContactsFormGrid.defaultProps = {
  setSelectedContact: () => {},
  selectedContacts: [],
  checkbox: false,
};

export default CompanyContactsFormGrid;
