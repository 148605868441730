import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { ADD_COMPANY_TO_SUPER_USER, CHANGE_COMPANY, MY_ASSIGNED_COMPANIES } from '../../graphql';
import { useAnchorElementState, useBooleanState, useGetUser, useSelectedCompany, useTextFieldInput } from '../../hooks';
import { cleanAllShopping } from '../../apollo/reactive-variables/localUpdates';
import useTextFieldValue from '../../hooks/useTextFieldValue';
import { openSiiFormWithoutAutoFill } from '../../helpers/sii-credentials-form-toggle';
import { formatText } from '../../helpers/text-parser';
import useGetMyCompanies from '../../hooks/useGetMyCompanies';
import { LoadingIconButton, TextLoadingButton } from '../buttons';
import GetUserToken from './GetUserToken';

const SelectCompanyNavItem = ({ ml, addCompany, first }) => {
  const user = useGetUser();
  const selectedCompany = useSelectedCompany();
  const [snackBarOpen, toggleSnackbar] = useBooleanState(false);
  const [searchValue, setSearchValue, resetSearchValue] = useTextFieldInput('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [companiesAnchor, openCompaniesAnchor, closeCompaniesAnchor] = useAnchorElementState(null);
  const { companies, loading: loadingCompanies } = useGetMyCompanies(
    {
      first,
      masterEntityNameIcontains: searchValue,
    },
    !companiesAnchor,
  );
  const [addCompanyRut, setaddCompanyRut, cleanValue] = useTextFieldValue('');
  const [openAllCompanies, , showAllCompanies, closeAllCompanies] = useBooleanState(false);
  const [changeCompany, { loading: loadingChangingCompany }] = useMutation(CHANGE_COMPANY);
  const [mutationAddCompanySuperuser, { loading: loadingAddCompany }] = useMutation(
    ADD_COMPANY_TO_SUPER_USER,
    {
      refetchQueries: [MY_ASSIGNED_COMPANIES],
      awaitRefetchQueries: true,
      variables: { companyRut: addCompanyRut },
      onCompleted: () => {
        toggleSnackbar();
        setSnackBarMessage('Empresa añadida');
        cleanValue();
      },
      onError: (error) => {
        setSnackBarMessage(error.message);
        toggleSnackbar();
      },
    },
  );
  const closeMenu = useCallback(() => {
    closeCompaniesAnchor();
    closeAllCompanies();
    resetSearchValue();
  }, []);

  const handleSelectCompany = useCallback((company) => {
    cleanAllShopping();
    closeAllCompanies();
    closeMenu();
    changeCompany({ variables: { companyId: company.id } });
  }, []);
  const openSii = useCallback(() => {
    closeCompaniesAnchor();
    openSiiFormWithoutAutoFill();
  }, [closeCompaniesAnchor]);
  const companyList = useMemo(() => companies.filter((company) => company.id
   !== selectedCompany?.id), [selectedCompany, companies]);
  const restOfCompanies = companyList.slice(4);
  return (
    <>
      <Snackbar open={snackBarOpen} onClose={toggleSnackbar} autoHideDuration={5000}>
        <SnackbarContent message={snackBarMessage} />
      </Snackbar>
      <TextLoadingButton
        sx={{
          ml,
          maxWidth: { xs: '100%', md: 300, lg: 700, xl: 1000 },
        }}
        size="small"
        onClick={openCompaniesAnchor}
        id="nav-company-selector"
        loading={loadingChangingCompany}
        endIcon={<ArrowDropDown color="primary" />}
        loadingPosition="end"
        typographyProps={{
          variant: 'h6',
          color: 'text.primary',
          noWrap: true,
        }}
      >
        {selectedCompany ? selectedCompany?.name : 'Mis empresas'}
      </TextLoadingButton>
      <Menu
        id="companies-menu"
        anchorEl={companiesAnchor}
        keepMounted
        open={Boolean(companiesAnchor)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: { xs: 280, md: 320 },
            maxHeight: '80%',
          },
        }}
      >
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem key="company-search">
            <TextField
              placeholder="Buscar..."
              value={searchValue}
              onChange={setSearchValue}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: loadingCompanies && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <ListItem key={selectedCompany?.id}>
            <ListItemText
              primary={selectedCompany?.name}
              primaryTypographyProps={{
                variant: 'h2',
                color: 'text.light',
                noWrap: true,
                fontWeight: 'bold',
              }}
            />
          </ListItem>
          {companyList.slice(0, 4).map((company) => (
            <ListItemButton key={company.id} onClick={() => handleSelectCompany(company)}>
              <ListItemText
                primary={company.masterEntity.name
                  || company.masterEntity.displayNationalIdentifier}
                primaryTypographyProps={{ variant: 'h2', color: 'text.light', noWrap: true }}
              />
            </ListItemButton>
          ))}
          {restOfCompanies.length > 0 && (
            <ListItemButton onClick={showAllCompanies}>
              <ListItemText component={Typography} variant="h2">
                {formatText('**<** Ver más empresas')}
              </ListItemText>
            </ListItemButton>
          )}
          <Divider />
          {user.isStaff ? (
            <>
              <ListSubheader
                id="nested-list-subheader-add-company"
                component={Typography}
                variant="h6"
                color="primary"
              >
                Agregar otra empresa
              </ListSubheader>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 3 }}>
                <TextField
                  variant="outlined"
                  name="adCompanyRut"
                  placeholder="Agregar empresa"
                  onChange={setaddCompanyRut}
                  value={addCompanyRut}
                />
                <Tooltip title="Agregar rut sin dígito verificador">
                  <LoadingIconButton
                    onClick={mutationAddCompanySuperuser}
                    sx={{ p: 0 }}
                    size="small"
                    loading={loadingAddCompany}
                    color="primary"
                    id="nav-add-company"
                  >
                    <Add />
                  </LoadingIconButton>
                </Tooltip>
              </Stack>
            </>
          ) : (
            addCompany
            && (
            <Grid style={{ width: '100%' }} container direction="row">
              <Button color="primary" id="nav-new-company" onClick={openSii}>
                +Agregar empresa
              </Button>
            </Grid>
            )
          )}
          { user.isSuperuser && <GetUserToken />}
        </List>
      </Menu>
      <Menu
        id="all-companies-menu"
        open={openAllCompanies}
        onClose={closeAllCompanies}
        anchorEl={companiesAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            maxWidth: { xs: 280, md: 420 },
            minWidth: { xs: 200, md: 300 },
            maxHeight: '70%',
          },
        }}
        sx={{ left: { xs: -280, md: -320 } }}
      >
        <List component="nav" aria-labelledby="nested-list-subheader">
          {restOfCompanies.map((company) => (
            <ListItemButton key={company.id} onClick={() => handleSelectCompany(company)}>
              <ListItemText
                primary={company.masterEntity.name
                  || company.masterEntity.displayNationalIdentifier}
                primaryTypographyProps={{ variant: 'h2', color: 'text.light', noWrap: true }}
              />
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
};

SelectCompanyNavItem.propTypes = {
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addCompany: PropTypes.bool,
  first: PropTypes.number,
};

SelectCompanyNavItem.defaultProps = {
  ml: 'auto !important',
  addCompany: true,
  first: 10,
};

export default SelectCompanyNavItem;
