import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Chip, Tooltip, Stack } from '@mui/material';
import Upload from '@mui/icons-material/Upload';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COMPANY_CONTRACT_GENERATION, GENERATE_COMPANY_FRAMEWORK_CONTRACT } from '../../graphql';
import FingoDialog from '../dialogs/FingoDialog';
import { useBooleanState, useSnackBars } from '../../hooks';
import useFetchPresignedUrl from '../../hooks/useFetchPresignedUrl';
import LegalRepresentativesComponent from './LegalRepresentativesComponent';
import ContractInfoComponent from './ContractInfoComponent';
import HeaderComponent from './HeaderComponent';
import EndorsementComponent from './EndorsementComponent';
import ContinueButtonComponent from './ContinueButtonComponent';
import HandleResponse from './HandleResponse';

const GenerateContractChip = ({ document, companyId }) => {
  const { addAlert } = useSnackBars();
  const [open, toggleDialog] = useBooleanState(false);
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const openUrl = useCallback((globalAppId) => fetchGivenId(globalAppId), [fetchGivenId]);
  const [legalRepresentatives, setLegalRepresentatives] = useState([]);
  const [endorsements, setEndorsements] = useState([]);
  const [contractInfo, setContractInfo] = useState({});

  const { loading } = useQuery(
    GET_COMPANY_CONTRACT_GENERATION,
    {
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
      skip: !open,
      onCompleted: (data) => {
        HandleResponse(
          data.company,
          setContractInfo,
          setLegalRepresentatives,
          setEndorsements,
        );
      },
    },
  );

  const contractInfoFinalObj = useMemo(() => {
    const { mainDisabled, ...rest } = contractInfo;
    return rest;
  }, [contractInfo]);

  const legalRepresentativesFinalArray = useMemo(() => legalRepresentatives.map((legalRep) => {
    const { id, mainDisabled, ...rest } = legalRep;
    return rest;
  }), [legalRepresentatives]);

  const endorsementFinalArray = useMemo(() => endorsements.map((end) => {
    const { id, mainDisabled, ...rest } = end;
    return rest;
  }), [endorsements]);

  const [genereateCompanyFrameworkContract, { loading: generateLoading }] = useMutation(
    GENERATE_COMPANY_FRAMEWORK_CONTRACT,
    {
      variables: {
        companyId,
        contractInfo: contractInfoFinalObj,
        legalRepresentatives: legalRepresentativesFinalArray,
        endorsements: endorsementFinalArray,
      },
      onCompleted: (data) => {
        openUrl(data.generateCompanyFrameworkContract.frameworkContract.globalAppId);
      },
      onError: (error) => addAlert({
        id: 'generate-contract-error',
        severity: 'error',
        message: `Error: ${error.message}`,
      }),
    },
  );

  return (
    <>
      <Tooltip title="Generar Contrato">
        <Chip
          size="small"
          label={document.label}
          onClick={toggleDialog}
          id={document.documentType.toLowerCase()}
          color="primary"
          variant="filled"
          icon={loading ? (
            <CircularProgress size={12} />
          ) : (
            <Upload />
          )}
          sx={{ '& .MuiChip-label': { fontSize: 11 }, marginInline: 0.2, marginTop: 0.5 }}
        />
      </Tooltip>
      <FingoDialog
        maxWidth="lg"
        open={open && !loading}
        handleClose={toggleDialog}
        title="Generación de Contrato"
        dialogActionButton={(
          <ContinueButtonComponent
            contractInfo={contractInfo}
            legalRepresentatives={legalRepresentatives}
            endorsements={endorsements}
            genereateCompanyFrameworkContract={genereateCompanyFrameworkContract}
            generateLoading={generateLoading}
          />
        )}
      >
        <Stack direction="column" spacing={2}>
          <HeaderComponent />
          <ContractInfoComponent
            contractInfo={contractInfo}
            setContractInfo={setContractInfo}
          />
          <LegalRepresentativesComponent
            legalRepresentatives={legalRepresentatives}
            setLegalRepresentatives={setLegalRepresentatives}
          />
          <EndorsementComponent
            endorsements={endorsements}
            setEndorsements={setEndorsements}
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

GenerateContractChip.propTypes = {
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    documentType: PropTypes.string,
    companyDocument: PropTypes.shape({
      id: PropTypes.string.isRequired,
      globalAppId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default GenerateContractChip;
