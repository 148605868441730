import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { collectionPriorityColors } from '../../constants';
import CollectionSummaryCard from './CollectionSummaryCard';

const CollectionSummaryCards = ({ collectionManagersByExpiration: {
  expiredCount,
  expired,
  unexpiredCount,
  unexpiredAmount,
} }) => {
  const expiredInt = parseInt(expired, 10);
  const unexpiredInt = parseInt(unexpiredAmount, 10);
  const totalCount = parseInt(expiredCount, 10) + parseInt(unexpiredCount, 10);
  const totalAmount = expiredInt + unexpiredInt;
  return (
    <Stack direction="row" spacing={1} width="100%">
      <CollectionSummaryCard
        label="Total facturas por cobrar"
        amount={totalCount}
        money={false}
      />
      <CollectionSummaryCard
        label="Total en cobranza"
        amount={totalAmount}
      />
      <CollectionSummaryCard
        label="Vencido"
        percent={totalAmount > 0 ? `${Math.trunc((expiredInt / totalAmount) * 100)} %` : '0%'}
        percentColor={collectionPriorityColors[1]}
        amount={expiredInt}
      />
      <CollectionSummaryCard
        label="Proximo a vencer"
        percent={totalAmount > 0 ? `${Math.trunc((unexpiredInt / totalAmount) * 100)} %` : '0%'}
        percentColor={collectionPriorityColors[3]}
        amount={unexpiredInt}
      />
    </Stack>
  );
};

CollectionSummaryCards.propTypes = {
  collectionManagersByExpiration: PropTypes.shape({
    expiredCount: PropTypes.number.isRequired,
    expired: PropTypes.number.isRequired,
    unexpiredCount: PropTypes.number.isRequired,
    unexpiredAmount: PropTypes.number.isRequired,
  }).isRequired,
};

export default CollectionSummaryCards;
